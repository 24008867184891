import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
	{
		path: '/',
		name: 'home',
		component: () => import('../views/Landing.vue'),
	},
	{
		path: '/search',
		name: 'search',
		component: () => import('../views/SearchView.vue'),
	},
	{
		path: '/landing',
		name: 'Landing',
		component: () => import('../views/Landing.vue'),
	},
	{
		path: '/recaptcha',
		name: 'ReCaptcha',
		component: () => import('../views/ReCaptcha.vue'),
	},
	{
		path: '/policy',
		name: 'Policy',
		component: () => import('../views/Policy.vue'),
	},
	{
		path: '/contact',
		name: 'Contact',
		component: () => import('../views/Contact.vue'),
	},
	{
		path: '/unsub/:data',
		name: 'Unsub',
		component: () => import('../views/Unsub.vue'),
	},
];

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
});

export default router;
